import React from "react";
import Header from "./Coman/Header";
import Footer from "./Coman/Footer";

export default function TermsofUse() {
  return (
    <>
      <Header />
     
      <div className="our-benefit pt-100 pb-100">
        <div className="container">
          <div className="heading3 text-center py-4">Terms of Use </div>
          <div className="list-benefit mt-40">
            <div className="row row-gap-32">
              <div className="col-md-12 col-sm-12">
                <h4 className=" ">1. Acceptance of Terms</h4>
                <p className="text-placehover pt-2">
                  By accessing or using Profit Plus, you agree to comply with
                  and be bound by these Terms of Use. If you do not agree with
                  these terms, you are prohibited from using the platform.
                </p>
                <h4 className="mt-3">2. Platform Usage</h4>
                <p className="text-placehover pt-2">
                  Profit Plus is an automated crypto trading platform designed
                  for users to leverage trading bots to execute trading
                  strategies. Users must be aware that all trades made through
                  the platform are executed automatically, based on the bot
                  settings chosen by the user. Profit Plus does not guarantee
                  profits and is not responsible for losses incurred through the
                  use of the platform.
                </p>
                <h4 className="mt-3">3. Eligibility</h4>
                <p className="text-placehover pt-2">
                  By using Profit Plus, you affirm that you are at least 18
                  years old, have the legal capacity to engage in financial
                  transactions, and are not barred from using the platform by
                  any applicable laws or regulations.
                </p>
                <h4 className="mt-3">4. Account Registration</h4>
                <p className="text-placehover pt-2">
                  To use Profit Plus, you must create an account, provide
                  accurate personal information, and maintain the security of
                  your login credentials. You are responsible for all activities
                  conducted through your account.
                </p>
                <h4 className="mt-3">5. Bot Plans and Services</h4>
                <p className="text-placehover pt-2">
                  Profit Plus offers multiple trading bots with different
                  pricing plans. Users must choose a bot and deposit the
                  required amount to activate it. The bots are designed to
                  execute trades automatically, and users are responsible for
                  ensuring the accuracy of their chosen bot settings.
                </p>
                <h4 className="mt-3">6. Deposits and Withdrawals</h4>
                <p className="text-placehover pt-2">
                  All deposits and withdrawals on Profit Plus are processed in
                  Arbitrum USDT. By using the platform, you agree to deposit and
                  withdraw in this supported currency. Profit Plus is not
                  responsible for any issues arising from incorrect wallet
                  addresses or user error during deposit or withdrawal
                  processes.
                </p>
                <h4 className="mt-3">7. Capping and Referral Incomes</h4>
                <ul className="ps-0 ps-md-4 mt-4">
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Capping :
                    </span>{" "}
                    <span className="text-placehover ">
                      Users are subject to a 2X cap on earnings from their bot
                      plans. Once the cap is reached, no additional income will
                      be earned until a new bot is purchased.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Direct Income :
                    </span>{" "}
                    <span className="text-placehover ">
                      Users earn a 5% direct income on each referral they make,
                      calculated based on the referral's bot plan purchase.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Binary Income :
                    </span>{" "}
                    <span className="text-placehover ">
                      Binary income is calculated at 10% daily, with a
                      requirement of two direct referrals to be eligible for
                      earnings. Daily capping is based on the user's bot amount.
                    </span>
                  </li>
                </ul>
                <h4 className="mt-3">8. Risk Disclaimer</h4>
                <p className="text-placehover pt-2">
                  Trading cryptocurrencies involves substantial risk. Profit
                  Plus does not guarantee that users will profit from the
                  trading strategies executed by the automated bots. Past
                  performance of bots does not guarantee future results, and all
                  investments made through the platform are subject to potential
                  loss.
                </p>
                <h4 className="mt-3">9. User Responsibilities</h4>
                <p className="text-placehover pt-2">
                  You agree to use Profit Plus responsibly and in compliance
                  with all applicable laws and regulations. Users should perform
                  their own research before engaging in any trading activities
                  and understand that Profit Plus does not provide financial
                  advice or act as a fiduciary.
                </p>
                <h4 className="mt-3">10. Termination</h4>
                <p className="text-placehover pt-2">
                  Profit Plus reserves the right to suspend or terminate your
                  access to the platform at any time, without notice, for
                  violations of these Terms of Use or any illegal activities
                  conducted through the platform.
                </p>
                <h4 className="mt-3">11. Amendments</h4>
                <p className="text-placehover pt-2">
                  Profit Plus may update or modify these Terms of Use at any
                  time without prior notice. It is the responsibility of the
                  user to regularly check the terms and remain informed of any
                  changes.
                </p>
                <h4 className="mt-3">12. Limitation of Liability</h4>
                <p className="text-placehover pt-2">
                  Profit Plus shall not be held liable for any direct, indirect,
                  incidental, or consequential losses resulting from the use of
                  the platform, including but not limited to bot malfunctions,
                  technical issues, or market volatility.
                </p>
                <h4 className="mt-3">13. Contact</h4>
                <p className="text-placehover pt-2">
                  If you have any questions about these Terms of Use, please
                  contact us at{" "}
                  <a href="mailto:support@profitplus.com">
                    [support@profitplus.com]
                  </a>
                  .
                </p>
                <p className="text-placehover pt-2">
                  By using Profit Plus, you acknowledge that you have read,
                  understood, and agree to be bound by these terms and
                  conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}
