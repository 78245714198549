import React from "react";

export default function Integrated() {
  return (
    <div>
      <div className="counter-block style-three pt-100 pb-100 ">
        <div className="container">
          <div className="row row-gap-32 flex-between">
            <div className="col-lg-6">
              <div className="heading3">
                Integrated on Multiple Blockchain Networks
              </div>
              <div className="count mt-40">
                <div className="item  gap-24 pb-20">
                  <div className="body2 text-placehover">
                    Profit Plus is designed with flexibility in mind, allowing
                    users to trade across multiple blockchain networks
                    effortlessly. It integrates with major networks like
                    Arbitrum, Ethereum, and more, ensuring that you have access
                    to a wide range of assets and opportunities.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-5 col-lg-6 list-img pr-80 pr-col-lg-0">
              <div className="bg-img">
                <div className="logo">
                <img src="assets/images/img/in-logo.svg" width={150} alt="" />
                </div>
                <div className="list-avatar">
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/in-arbitrum.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/polygon.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/optimism.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/eth.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/bnb_chain.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/tron.svg" alt="" />
                  </div>
                  <div className="avatar">
                    {" "}
                    <img src="assets/images/img/ton.svg" alt="" />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
