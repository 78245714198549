import React from "react";

export default function Create() {
  return (
    <>
      <div className="counter-block style-three pt-100 pb-100 ">
        <div className="container">
          <div className="row row-gap-32 flex-between">
            <div className="col-xl-6 col-lg-5">
              <div className="heading3">
                Join Profit Plus & Unleash Vowing Profitability
              </div>
              <div className="count mt-40">
                <div className="item  gap-24 pb-20">
                  <div className="heading4 text-yellow">
                    10k+ Satisfied Users<span> </span>
                  </div>
                  <div className="body2 text-placehover">
                    With a growing community of over 10,000 satisfied users,
                    Profit Plus continues to deliver exceptional results. Our
                    user-friendly interface and advanced automation tools have
                    empowered traders to achieve their  financial goals with
                    ease.
                  </div>
                </div>
                <div className="line-dark" />
                <div className="item gap-24 pt-20">
                  <div className="heading4 text-yellow">
                    $150k+ Funds Deployed<span> </span>
                  </div>
                  <div className="body2 text-placehover">
                    Profit Plus has become a trusted platform for traders with
                    over $150,000 in deployed capital. Our secure infrastructure
                    and powerful trading solutions help users make informed
                    decisions, maximize returns, and protect their assets.
                  </div>
                  <div className="body2 text-placehover pt-3">
                  Join us today and become part of a growing movement towards smarter & automated trading.

                  </div>
                </div>
              </div>
              <a
                className="button button-yellow-hover text-on-surface mt-40"
                href="https://app.profitplus.finance/"
                target="_blank"
              >
                {" "}
                <span>
                  {" "}
                  <span></span>
                </span>
                <span className="bg-yellow"> Join Now</span>
              </a>
            </div>
            <div className="col-xl-6 col-lg-7 ">
   <img src="assets/images/img/join-profit-plus--unleash-vowing-profitability.png" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
