import React from "react";
import Header from "../Coman/Header";
import Hero from "./Hero";
import Main from "./Main";
import Footer from "../Coman/Footer";
import Create from "./Create";
import Build from "./Build";
import Provide from "./Provide";
import Services from "./Services";
import Subscribe from "./Subscribe";
import Embrace from "./Embrace";
import Integrated from "./Integrated";

export default function Index() {
  return (
    <>
      <Header />
      <Hero />
      <Main />
      <Create />
      <Build />
      <Integrated/>
      <Services />
      <Embrace/>
      <Provide />
      <Subscribe />
      <Footer />
    </>
  );
}
