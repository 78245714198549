import React from "react";

export default function Embrace() {
  return (
    <>
      <div className="our-benefit pt-100 pb-100">
        <div className="container">
          <div className="heading3">Why Embrace Profit Plus?</div>
          <div className="list-benefit mt-40">
            <div className="row row-gap-32">
              <a className="col-md-6 ">
                <div className="heading6 pb-20">Automated Trading</div>
                <div className="line-dark"> </div>
                <div className="text-placehover pt-20">
                  Our platform offers advanced automation tools that allow you
                  to set custom trading strategies, ensuring you never miss an
                  opportunity.
                </div>
              </a>
              <a className="col-md-6 ">
                <div className="heading6 pb-20">User-Friendly Interface</div>
                <div className="line-dark"> </div>
                <div className="text-placehover pt-20">
                  Whether you're new to crypto trading or a seasoned expert, our
                  intuitive interface makes it easy to manage and optimize your
                  trades.
                </div>
              </a>
              <a className="col-md-6 ">
                <div className="heading6 pb-20">Real-Time Analytics</div>
                <div className="line-dark"> </div>
                <div className="text-placehover pt-20">
                  Profit Plus enables access to real-time market data and quick
                  analytics to make informed decisions on the go.
                </div>
              </a>
              <a className="col-md-6 ">
                <div className="heading6 pb-20">Secure & Transparent</div>
                <div className="line-dark"> </div>
                <div className="text-placehover pt-20">
                  Security is our top priority, with state-of-the-art encryption
                  and transparent processes that keep your assets safe.
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
