import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Component/Home/Index';
import TermsofUse from './Component/TermsofUse';
import PrivacyPolicy from './Component/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />}/>
      <Route path="/terms-of-use" element={<TermsofUse />}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
      
    </Routes>
  </BrowserRouter>
  );
}

export default App;
