import React from "react";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      <div id="footer">
        <div className="footer-block bg-black-surface pt-60">
          <div className="container">
            <div className="heading flex-between">
              <div className="footer-company-infor d-flex flex-column gap-20">
                <a className="logo" href="#">
          <img src="assets/images/img/logo.svg" width={139} height={60} alt="" />
                </a>
              </div>
              {/* <div className="footer-navigate">
                <ul className="flex-item-center gap-40">
                  <li className="flex-center">
                    <a className="text-subtitle text-white" href="index-2.html">
                      Home
                    </a>
                  </li>
                  <li className="flex-center">
                    <a
                      className="text-subtitle text-white"
                      href="service-one.html"
                    >
                      Services
                    </a>
                  </li>
                  <li className="flex-center">
                    <a className="text-subtitle text-white" href="about.html">
                      About
                    </a>
                  </li>
                  <li className="flex-center">
                    <a className="text-subtitle text-white" href="blog.html">
                      Blog
                    </a>
                  </li>
                  <li className="flex-center">
                    <a className="text-subtitle text-white" href="contact.html">
                      Contact
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="list-social flex-item-center gap-12">
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center text-black"
                  href="#"
                  target="_blank"
                >
         <i class="fa-brands fa-facebook-f"></i>
                </a>
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center"
                  href="#"
                  target="_blank"
                >
             <i class="fa-brands fa-linkedin-in text-black"></i>
                </a>
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center"
                  href="#"
                  target="_blank"
                >
             <i class="fa-brands fa-x-twitter  text-black"></i>
                </a>
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center"
                  href="#"
                  target="_blank"
                >
             <i class="fa-brands fa-telegram text-black"></i>
                </a>
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center"
                  href="#"
                  target="_blank"
                >
               <i class="fa-brands fa-instagram text-black"></i>
                </a>
                <a
                  className="item bg-blue button-blue-hover bora-50 w-40 h-40 flex-center"
                  href="#"
                  target="_blank"
                >
              <i class="fa-brands fa-youtube text-black"></i>
                </a>
              </div>
            </div>
            {/* <div className="company-contact flex-center gap-16 mt-32 flex-wrap">
              <div className="phone flex-item-center gap-8">
                <i className="ph ph-phone text-placehover fs-24" />
                <span className="text-button-uppercase text-placehover">
                  012 345 6789
                </span>
              </div>
              <span className="text-placehover">|</span>
              <div className="mail flex-item-center gap-8">
                <i className="ph-light ph-envelope text-placehover fs-24" />
                <span className="text-button-uppercase text-placehover">
                  aizan@gmail.com
                </span>
              </div>
              <span className="text-placehover">|</span>
              <div className="location flex-item-center gap-8">
                <i className="ph-light ph-map-pin text-placehover fs-24" />
                <span className="text-button-uppercase text-placehover">
                  710 1st St. Easton, PA 18042 | Chester County
                </span>
              </div>
            </div> */}
          </div>
          <div className="bg-black-surface mt-32">
            <div className="container bg-black-surface">
              <div className="line-dark" />
              <div className="footer-bottom flex-between pt-12 pb-12 flex-wrap">
                <div className="left-block flex-item-center">
                  <div className="copy-right text-placehover caption1">
                    ©{year} Profit Plus . All Rights Reserved.
                  </div>
                </div>
                <div className="nav-link flex-item-center gap-8">
                  <a
                    className="text-placehover caption1 hover-underline"
                    href="/terms-of-use"
                  >
                   Terms of Use
                  </a>
                  <span className="text-placehover caption1">|</span>
                  <a
                    className="text-placehover caption1 hover-underline"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  {/* <span className="text-placehover caption1">|</span> */}
                  {/* <a
                    className="text-placehover caption1 hover-underline"
                    href="#!"
                  >
                    Cookie Policy
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
