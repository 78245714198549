import React from "react";
import Header from "./Coman/Header";
import Footer from "./Coman/Footer";

export default function PrivacyPolicy() {
  return (
    <>
      <Header />

      <div className="our-benefit pt-100 pb-100">
        <div className="container">
          <div className="heading3 text-center py-4">Privacy Policy </div>
          <div className="list-benefit mt-40">
            <div className="row row-gap-32">
              <div className="col-md-12 col-sm-12">
                <h4 className=" ">Effective Date: November 2024</h4>
                <p className="text-placehover pt-2">
                  At Profit Plus, we are committed to safeguarding the privacy
                  and personal information of our users. This Privacy Policy
                  outlines how we collect, use, disclose, and protect your
                  information when you access our platform or use any of our
                  services. By using Profit Plus, you agree to the practices
                  described in this policy.
                </p>

                <h4 className="mt-3">1. Information We Collect</h4>
                <p className="text-placehover pt-2">
                  We collect the following types of information to provide and
                  improve our services:
                </p>
                <ul className="ps-0 ps-md-4 mt-4">
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Personal Information :
                    </span>{" "}
                    <span className="text-placehover ">
                      When you register on Profit Plus, we may collect personal
                      details such as your name, email address, phone number,
                      and any other identifying information you provide.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Wallet Information :
                    </span>{" "}
                    <span className="text-placehover ">
                      If you engage in transactions on Profit Plus, we may
                      collect payment details and wallet addresses.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Usage Data :
                    </span>{" "}
                    <span className="text-placehover ">
                      We gather information about how you interact with the
                      platform, including device information, IP address,
                      browser type, operating system, and page interaction data.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Cookies and Tracking :
                    </span>{" "}
                    <span className="text-placehover ">
                      Profit Plus uses cookies and similar technologies to track
                      user activity and store certain information. You can
                      manage cookie preferences through your browser settings.
                    </span>
                  </li>
                </ul>
                <h4 className="mt-3">2. How We Use Your Information</h4>
                <p className="text-placehover pt-2">
                  We use the information collected to:
                </p>
                <ul className="ps-0 ps-md-4 mt-4">
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Provide Services :
                    </span>{" "}
                    <span className="text-placehover ">
                      To process your transactions, facilitate trading, and
                      manage your account.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Enhance User Experience :
                    </span>{" "}
                    <span className="text-placehover ">
                      To improve our platform functionality and provide
                      personalized recommendations.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Communicate :
                    </span>{" "}
                    <span className="text-placehover ">
                      To send important updates, newsletters, and promotional
                      offers.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Security and Fraud Prevention :
                    </span>{" "}
                    <span className="text-placehover ">
                      To monitor and prevent fraudulent activities, protect your
                      account, and comply with legal obligations.
                    </span>
                  </li>
                </ul>
                <h4 className="mt-3">3. Information Sharing and Disclosure</h4>
                <p className="text-placehover pt-2">
                  Profit Plus does not sell or rent your personal information to
                  third parties. However, we may share your data in the
                  following circumstances:
                </p>
                <ul className="ps-0 ps-md-4 mt-4">
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Service Providers :
                    </span>{" "}
                    <span className="text-placehover ">
                      We may share your information with trusted third-party
                      service providers who assist us in delivering services,
                      such as payment processors and analytics providers.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Legal Compliance :
                    </span>{" "}
                    <span className="text-placehover ">
                      We may disclose your information if required by law, to
                      comply with legal processes, or to protect our rights and
                      the safety of our users.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2 "
                        style={{ fontSize: "10px" }}
                      ></i>
                      Business Transfers :
                    </span>{" "}
                    <span className="text-placehover ">
                      In the event of a merger, acquisition, or sale of Profit
                      Plus assets, your information may be transferred as part
                      of the transaction.
                    </span>
                  </li>
                </ul>
                <h4 className="mt-3">4. Security of Your Information</h4>
                <p className="text-placehover pt-2">
                  Profit Plus employs industry-standard security measures to
                  protect your personal and financial information from
                  unauthorized access, alteration, or disclosure. While we
                  strive to protect your data, no security system is entirely
                  foolproof, and we cannot guarantee absolute security.
                </p>
                <h4 className="mt-3">5. Your Rights and Choices</h4>
                <p className="text-placehover pt-2">
                  You have the following rights regarding your personal
                  information:
                </p>
                <ul className="ps-0 ps-md-4 mt-4">
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Access and Correction :
                    </span>{" "}
                    <span className="text-placehover ">
                      You can access and update your personal information by
                      logging into your account settings.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2"
                        style={{ fontSize: "10px" }}
                      ></i>
                      Data Portability :
                    </span>{" "}
                    <span className="text-placehover ">
                      We may disclose your information if required by law, to
                      comply with legal processes, or to protect our rights and
                      the safety of our users.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2 "
                        style={{ fontSize: "10px" }}
                      ></i>
                      Deletion :
                    </span>{" "}
                    <span className="text-placehover ">
                      You can request the deletion of your personal data,
                      subject to any legal requirements or legitimate business
                      purposes.
                    </span>
                  </li>
                  <li>
                    <span className="heading8">
                      {" "}
                      <i
                        class="ph ph-circle me-2 "
                        style={{ fontSize: "10px" }}
                      ></i>
                      Marketing Preferences :
                    </span>{" "}
                    <span className="text-placehover ">
                      You can opt-out of marketing communications by following
                      the unsubscribe instructions in any emails or by adjusting
                      your account settings.
                    </span>
                  </li>
                </ul>
                <h4 className="mt-3">6. Data Retention</h4>
                <p className="text-placehover pt-2">
                  We retain your personal information for as long as necessary
                  to provide our services, fulfill legal obligations, and
                  resolve disputes. Once your information is no longer required,
                  we will securely delete or anonymize it.
                </p>
                <h4 className="mt-3">7. Third-Party Links</h4>
                <p className="text-placehover pt-2">
                  Profit Plus may contain links to third-party websites or
                  services. We are not responsible for the privacy practices of
                  these external sites. We recommend reviewing their privacy
                  policies before sharing any personal information.
                </p>
                <h4 className="mt-3">8. Children’s Privacy</h4>
                <p className="text-placehover pt-2">
                  Profit Plus is not intended for individuals under the age of
                  18. We do not knowingly collect personal information from
                  children. If you believe that we have inadvertently collected
                  information from a minor, please contact us immediately, and
                  we will take appropriate steps to remove the data.
                </p>
                <h4 className="mt-3">9. Changes to This Privacy Policy</h4>
                <p className="text-placehover pt-2">
                  We may update this Privacy Policy from time to time. Any
                  changes will be posted on this page with the updated effective
                  date. We encourage you to review this policy periodically to
                  stay informed about how we protect your information.
                </p>
                <h4 className="mt-3">10. Contact Us</h4>
                <p className="text-placehover pt-2">
                  If you have any questions or concerns about this Privacy
                  Policy or how we handle your personal information, please
                  contact us at:
                </p>
                <p className="pt-2">
                  <span className="heading8">Email :</span>{" "}
                  <a href="mailto-support@profitplus.com">
                    {" "}
                    support@profitplus.com
                  </a>
                </p>
                <hr />
                <p className="text-placehover pt-2">
                  By using Profit Plus, you agree to the terms outlined in this
                  Privacy Policy. If you do not agree with these terms, please
                  discontinue use of the platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>

    </>
  );
}
