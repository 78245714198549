import React from "react";

export default function Provide() {
  return (
    <div>
      <div className="style-purple">
        <div className="counter bg-surface pt-80 pb-80">
          <div className="container">
            <div className="row flex-between row-gap-40">
              <div className="col-lg-5 pr-16 pr-col-lg-0">
                <div className="heading3 text-on-surface">
                  Get Started Today!
                </div>
                <div className="body2 text-secondary mt-24">
                  Profit Plus is your gateway to a smarter, faster & more
                  profitable trading experience. Sign up today & join us for
                  unlocking the full potential of automation in the crypto
                  space.
                </div>
              </div>
              <div className="col-lg-6 pl-0 pl-col-lg-0 flex-between gap-16">
                <div className="item">
                  <div className="icon flex-center w-60 h-60 bg-green bora-50 d-inline-flex">
                    {" "}
                    <img src="assets/images/img/trading_partners.png" width={50}  alt="" />
                  </div>
                  <div className="count-number heading2 text-on-surface mt-20">
                    10+
                  </div>
                  <div className="body1 text-secondary mt-8">
                    Trading Partners
                  </div>
                </div>
                <div className="item">
                  <div className="icon flex-center w-60 h-60 bg-green bora-50 d-inline-flex">
                    {" "}
                    <img src="assets/images/img/platforms_integrated.png" width={50}  alt="" />
                  </div>
                  <div className="count-number heading2 text-on-surface mt-20">
                    50+
                  </div>
                  <div className="body1 text-secondary mt-8">
                    Platforms Integrated
                  </div>
                </div>
                <div className="item">
                  <div className="icon flex-center w-60 h-60 bg-green bora-50 d-inline-flex">
                  <img src="assets/images/img/community_members.png" width={50}  alt="" />
                  </div>
                  <div className="flex-item-center mt-20">
                    <div className="count-number heading2 text-on-surface">
                      10k+
                    </div>
                    <span className="heading2 text-on-surface"></span>
                  </div>
                  <div className="body1 text-secondary mt-8">
                    Community Members
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
