import React from "react";

export default function Services() {
  return (
    <div>
      <div className="shared-bg-service">
        <div className="services-block style-three pt-100">
          <div className="container">
            <div className="heading text-center">
              <div className="heading3">Quick Perks & Benefits</div>
            </div>
            <div className="list row row-gap-32 mt-40">
              <div className="col-xl-8 col-12 scroll-bottom-to-top1">
                <div className="service-item hover-box-shadow pl-24 pr-24 pt-32 pb-40 bg-line-dark bora-24 h-100">
                  <a
                    className="service-item-main flex-column-center text-center"
                    href="#"
                  >
                    <div className="heading pb-20">
                      <div className="heading5">Launched on Arbitrum</div>
                    </div>
                    <div className="line" />
                    <div className="desc pt-20">
                      <div className="heading8">
                        {" "}
                        By leveraging the power of the Arbitrum network, Profit
                        Plus offers traders a superior experience with
                        lightning-fast transaction speeds, exceptional
                        scalability, and minimal transaction costs. This means
                        you can execute trades more efficiently, without
                        worrying about network congestion or high fees eating
                        into your profits. Profit Plus ensures that traders can
                        seamlessly engage with the market by executing trades or
                        managing long-term strategies.
                      </div>
                      <div className="body3 mt-8">
                        Speed, Efficient, and Low Fees ✔
                      </div>
                      {/* <u className="display-inline-block fw-700 mt-20">
                        Learn more
                      </u> */}
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-12 scroll-bottom-to-top2">
                <div className="service-item hover-box-shadow pl-24 pr-24 pt-32 pb-40 bg-line-dark bora-24 h-100">
                  <a
                    className="service-item-main flex-column-center text-center"
                  
                  >
                    <div className="heading pb-20">
                      <div className="heading8">Trader Friendly</div>
                    </div>
                    <div className="line" />
                    <div className="desc pt-20">
                      <div className="heading8">
                        With Arbitrum, traders gain a competitive edge through
                        optimized performance and cost-effective trading
                        solutions.’
                      </div>
                      {/* <div className="body3 mt-8">
                        Engage and assist your website visitors with our
                        intelligent
                      </div>
                      <u className="display-inline-block fw-700 mt-20">
                        Learn more
                      </u> */}
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-12 scroll-bottom-to-top1">
                <div className="service-item hover-box-shadow pl-24 pr-24 pt-32 pb-40 bg-line-dark bora-24 h-100">
                  <a
                    className="service-item-main flex-column-center text-center"
                  
                  >
                    <div className="heading pb-20">
                      <div className="heading5">Expert Surveillance</div>
                    </div>
                    <div className="line" />
                    <div className="desc pt-20">
                      <div className="heading8">
                        Benefit from expert insights, community-led discussions,
                        and peer-to-peer learning to enhance your trading
                        journey. At Profit Plus, you're not just a user; you're
                        part of a dynamic ecosystem designed to elevate your
                        success.
                      </div>
                      {/* <div className="body3 mt-8">
                        Engage and assist your website visitors with our
                        intelligent
                      </div>
                      <u className="display-inline-block fw-700 mt-20">
                        Learn more
                      </u> */}
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-xl-8 col-12 scroll-bottom-to-top2">
                <div className="service-item hover-box-shadow pl-24 pr-24 pt-32 pb-40 bg-line-dark bora-24 h-100">
                  <a
                    className="service-item-main flex-column-center text-center"
                  
                  >
                    <div className="heading pb-20">
                      <div className="heading5">Community Centric</div>
                    </div>
                    <div className="line" />
                    <div className="desc pt-20">
                      <div className="heading8">
                        Profit Plus is more than just a trading platform – it's
                        a thriving community where traders from all levels come
                        together to share knowledge, discuss strategies, and
                        grow collectively. Whether you're looking to improve
                        your trading techniques or seeking advice from
                        experienced traders, our platform encourages
                        collaboration and support.
                      </div>
                      <div className="body3 mt-8">
                      Learn, Share, and Grow Together

                      </div>
                      {/* <u className="display-inline-block fw-700 mt-20">
                        Learn more
                      </u> */}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-partner-slide style-three pt-40 pb-40">
          <div className="list">
            <div className="item">
              <div className="text">
              Unleash Smarter Trading & Greater Profits
              </div>
            </div>
            <div className="item">
              <div className="text">
              Unleash Smarter Trading & Greater Profits
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
