import React from "react";

export default function Main() {
  return (
    <div>
      <div className="service-block list-service style-one pt-100 pb-100">
        <div className="container">
          <div className="heading text-center">
            <div className="heading3 text-center">
              Designed To Upscale Your Trading Game
            </div>
            <div className="body2 text-placehover mt-12 text-center">
              Profit Plus is designed to
            </div>
          </div>
          <div className="list row row-gap-32 mt-40">
            <div className="col-lg-3 col-sm-6 scroll-bottom-to-top1">
              <div className="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100">
                <a className="service-item-main" >
                  <div className="heading">
                  <img src="assets/images/img/customizable_trading_bots.svg" width={60} alt="Customizable Trading Bots" />
                  </div>
                  <div className="desc mt-24">
                    <div className="heading7">Customizable Trading Bots :</div>
                    <div className="text-placehover mt-4">
                      Tailor Bots for automating trades 24/7 across multiple
                      markets.
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 scroll-bottom-to-top2">
              <div className="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100">
                <a className="service-item-main">
                  <div className="heading">
                 <img src="assets/images/img/strategy_marketplace.svg" width={60} alt="Strategy Marketplace" />
                  </div>
                  <div className="desc mt-24">
                    <div className="heading7">Strategy Marketplace :</div>
                    <div className="text-placehover mt-4">
                      Explore & utilize proven trading strategies shared by
                      experts in the community.
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 scroll-bottom-to-top3">
              <div className="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100">
                <a className="service-item-main" >
                  <div className="heading">
                  <img src="assets/images/img/low_fees.svg" width={60} alt="Low Fees" />
                  </div>
                  <div className="desc mt-24">
                    <div className="heading7">Low Fees :</div>
                    <div className="text-placehover mt-4">
                      Enjoy competitive & close to zero fees powered by
                      Arbitrum’s efficient infrastructure.
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 scroll-bottom-to-top4">
              <div className="service-item hover-box-shadow pl-32 pr-32 pt-24 pb-24 bora-24 h-100">
                <a className="service-item-main" >
                  <div className="heading">
                  <img src="assets/images/img/comprehensive_tutorials.svg" width={60} alt="Comprehensive Tutorials" />
                  </div>
                  <div className="desc mt-24">
                    <div className="heading7">Comprehensive Tutorials :</div>
                    <div className="text-placehover mt-4">
                      Our step-by-step guides & tutorials to make it easy to get
                      started.
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
