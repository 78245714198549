import React, { useState } from "react";

export default function Header() {
  const [isOpen, setIsOpen] = useState (false);

  // Step 2: Toggle function to change the state
  const toggleHeader = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="header-menu style-four style-purple bg-black-surface">
        <div className="container">
          <div className="header-main flex-between">
            <a className="logo" href="/">
          <img src="assets/images/img/logo.svg" width={139} height={60} alt="" />
            </a>
           
            <div className="right-block flex-item-center">
              {/* <div className="search-icon pr-24 pointer">
                <i className="ph ph-magnifying-glass text-white fs-24 display-block" />
              </div> */}
              <div className="menu-humburger display-none pr-24 pointer">
                <i className="ph ph-list text-white fs-24" onClick={toggleHeader}/>
              </div>
              <a href="https://app.profitplus.finance/" target="_black" className="call-block flex-item-center gap-8 bg-white bora-99 p-2 px-3">
                <i className="ph ph-wallet p-4 bora-50 bg-on-surface text-white" />
                <div className="text-on-surface fw-700">Launch app</div>
              </a>
            </div>
          </div>
          <div id="menu-mobile-block" className={isOpen ? "open" : ""}>
            <div className="menu-mobile-main">
              <div className="heading flex-between">
                <a className="logo" href="/">
                <img src="assets/images/img/logo.svg" width={139} height={60} alt="" />
                </a>
                <div className="close-block">
                  {" "}
                  <i className="ph-bold ph-x d-block fs-18 text-white pointer" onClick={toggleHeader} />
                </div>
              </div>

              <a href="https://app.profitplus.finance/" target="_blank" className="call-block flex-item-center gap-8 bg-white bora-99 p-2 px-3 mx-3">
                <i className="ph ph-wallet p-4 bora-50 bg-on-surface text-white" />
                <div className="text-on-surface fw-700">Launch app</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
