import React from "react";

export default function Subscribe() {
  return (
    <>
      <div className="section-form-chat pt-100 pb-100">
        <div className="bg-img">
          {" "}
          <img src="assets/images/img/bg-chatbot1.png" alt="" />
        </div>
        <div className="container">
          <div className="heading2 text-center">Join Now</div>
          <div className="body2 text-placehover text-center mt-24">
            Join Profit Plus today & unlock the full potential of automated
            trading using our powerful tools and resources that help you succeed
            in the dynamic world of cryptocurrency.
          </div>
          <div className="row flex-center">
            <div className="col-xl-6">
              <div className="form-chat-block mt-60">
                <div className="form-content bora-16 overflow-hidden">
                  <div className="heading bg-black pt-28 pb-28 pl-40 ">
                    <div className=" heading6 ">Community</div>
                  </div>
                  <div className="content p-40 bg-on-surface">
                    <ul className="chatbox"> </ul>
                    <div className="heading7">
                      Connect with thousands of traders who share insights,
                      strategies, and experiences, helping you grow and thrive.
                    </div>
                    <a
                      className="button button-yellow-hover text-on-surface mt-40"
                      href="#"
                    >
                      {" "}
                      <span>
                        {" "}
                        <span />
                      </span>
                      <span className="bg-yellow">Join Community</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="form-chat-block mt-60">
                <div className="form-content bora-16 overflow-hidden">
                  <div className="heading bg-black pt-28 pb-28 pl-40 ">
                    <div className=" heading6 ">Get Started</div>
                  </div>
                  <div className="content p-40 bg-on-surface">
                    <ul className="chatbox"> </ul>
                    <div className="heading7">
                      Sign up now & take the first step toward smarter, more
                      profitable & efficient trading with Profit Plus!
                    </div>
                    <a
                      className="button button-yellow-hover text-on-surface mt-40"
                      href="https://app.profitplus.finance/"
                      target="_blank"
                    >
                      {" "}
                      <span>
                        {" "}
                        <span />
                      </span>
                      <span className="bg-yellow">Sign Up Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
