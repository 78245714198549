import React from "react";

export default function Build() {
  return (
    <>
      <div className="style-pricing">
        <div className="section-pricing pt-100 pb-100">
          <div className="container">
            <div className="row flex-between">
              <div className="heading text-center">
                <div className="heading3 text-center">
                  Where Does Profit Plus Deploy Trading Capital?
                </div>
                <div className="body2 text-placehover mt-12 text-center">
                  Profit Plus uses its innovative Trading Bots to execute trades
                  across various markets using <br /> customized trading
                  strategy that archives 100% profit accuracy.
                </div>
              </div>

              <div className="col-xl-12 col-12 pl-65">
                <div className="list-pricing mt-40" data-name="monthly">
                  <div className="row ">
                    <div className="col-xl-3 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/arbitrage_trading.svg"
                            height={80}
                            alt=" Arbitrage Trading"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          Arbitrage Trading
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Profiting from price differences across multiple
                          exchanges by buying low and selling high instantly.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/intraday_strategies.svg"
                            height={80}
                            alt="Intraday Strategies"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          Intraday Strategies
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Trading within the same day, focusing on short-term
                          price movements for quick profits.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/spot_trading.svg"
                            height={80}
                            alt="Spot Trading"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          Spot Trading
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Buying and selling assets on the spot for immediate
                          settlement at current market prices.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/futures_options.svg"
                            height={80}
                            alt="Futures & Options"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          Futures & Options
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Trading contracts that allow you to speculate or hedge
                          on future price movements of assets.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-xl-4 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/mev_trading.svg"
                            height={80}
                            alt="MEV Trading"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          MEV Trading
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Extracting maximum profit from blockchain transactions
                          by optimizing their placement within the block.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/high_frequency_trading.svg"
                            height={80}
                            alt="High-Frequency Trading"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          High-Frequency Trading
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Utilizing algorithms to execute a large number of
                          trades in milliseconds, capitalizing on minute price
                          fluctuations.
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-12 mt-3">
                      <div className="pricing-item bg-line-dark p-40 bora-20 h-100">
                        <div class="bg-img d-flex justify-content-center">
                          {" "}
                          <img
                            src="assets/images/img/pre_market_trading.svg"
                            height={80}
                            alt=" Pre-Market Trading"
                          />
                        </div>
                        <div className="heading7 pt-2 text-white text-center">
                          Pre-Market Trading
                        </div>
                        <div className="body3 text-placehover mt-12 text-center">
                          Executing trades before official market hours to take
                          advantage of overnight news or events.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
